const logotext = "RHA";
const meta = {
    title: "Hudson Rha",
    description: "I’m Hudson Rha and I am currently a Freshmen at Case Western Reserve University. I am double majoring in Computer Science and Finance.",
};

const introdata = {
    title: "Hi! I’m Hudson Rha",
    animated: {
        first: "I love coding",
        second: "I develop websites",
        third: "I develop mobile apps",
    },
    description: "Innovating what the world craves",
    your_img_url: "https://i.imgur.com/z7UzR6v_d.webp?maxwidth=1520&fidelity=grand",
};

const dataabout = {
    title: "About",
    aboutme: (
      <div>
        Hi, my name is Hudson Rha and I am currently a sophomore at Case Western Reserve University. I am majoring in Computer Science with a minor in Philosophy and AI.
      </div>
    ),
  };
const worktimeline = [
    {
        jobtitle: "Software Engineering Intern",
        where: "CECO Environmental",
        date: "June 2024 - August 2024",
    },
    {
        jobtitle: "Software Engineering Intern",
        where: "MSBC Group",
        date: "June 2023 - August 2023",
    },
    {
        jobtitle: "Software Engineering Intern",
        where: "Buzzbeaterz Inc.",
        date: "June 2022 - August 2022",
    },
    
];

const skills = [{
        name: "Python",
        value: 100,
    },
    {
        name: "Djano",
        value: 85,
    },
    {
        name: "Javascript",
        value: 80,
    },
    {
        name: "React",
        value: 60,
    },
    {
        name: "Jquery",
        value: 85,
    },
];

const services = [{
        title: "Languages",
        description: "Python, React/React Native, JavaScript, Redux, HTML/CSS",
    },
    {
        title: "Cloud & Containerization",
        description: "Docker, Kubernetes (K8s), CI/CD pipelines",
    },
    {
        title: "Version Contol & DevOps",
        description: "Git, SCRUM, Agile (familiar with the Agile Manifesto), Linux (Ubuntu, RHEL)",
    },
];

const dataportfolio = [
    {
        img: "https://i.imgur.com/YuhEHcc_d.webp?maxwidth=760&fidelity=grand",
        description: "Social media app for travelers. It grew to over 100 active users within the first day.",
        link: "https://www.traversego.com",
    },
    {
        img: "https://i.imgur.com/f8uTBg1_d.webp?maxwidth=760&fidelity=grand",
        description: "Turning victim memories into leads for crime investigations through facial description",
        link: "https://devpost.com/software/visualent-1lqgcd",
    },
    {
        img: "https://i.imgur.com/5AN1UR6_d.webp?maxwidth=760&fidelity=grand",
        description: "AI chatbot to allow consumers to interact with a menu",
        link: "https://mediafiles.botpress.cloud/27aa76fc-ab14-40a0-9a9c-996170e6f9d4/webchat/bot.html",
    },
    {
        img: "https://i.imgur.com/WfegAMA.png",
        description: "Website that makes API call to send out email",
        link: "https://www.stolenden.com",
    },
    
    
];

const contactConfig = {
    YOUR_EMAIL: "hudsonrha1@gmail.com",
    YOUR_FONE: "718-413-8710",
    description: "I am open to connecting with professionals and like-minded individuals who share a passion for finance and technology. Feel free to reach out to me if you are interested in collaborating, sharing ideas, or exploring potential opportunities.",
    YOUR_SERVICE_ID: "service_ixv6n4z",
    YOUR_TEMPLATE_ID: "template_86cxblb",
    YOUR_USER_ID: "il88CgQZsdegM4Td_",
};

const socialprofils = {
    github: "https://github.com/rhahudson1",
    instagram: "https://instagram.com/rhahudson",
    linkedin: "https://www.linkedin.com/in/hudsonrha/",
    twitter: "https://twitter.com/rhahudson",
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};